import { useEffect, useRef, useState } from 'react';
import { Outlet, Route, Routes, useSearchParams } from 'react-router-dom';
import { App as AntApp, Layout } from '@jll/react-ui-components';
import GoogleFont from 'GoogleFont';

import PageHeader from 'components/header/PageHeader';
import { useInitialValue } from 'hooks/useInitialValue';
import { useAuth } from 'providers/AuthProvider';
import { fetchAppConfig } from 'store/appConfigSlice';
import {
    exitPresentationMode,
    initializeFromSearchParams,
    selectPresentationModeEnabled,
} from 'store/applicationModeSlice';
import { fetchUserSettings } from 'store/userSettingsSlice';
import { useThemeChanger } from 'themes/ThemeProvider';
import { Configuration } from 'types/Configuration';
import User from 'types/Users/User';
import UserSettings from 'types/Users/UserSettings';
import datadog from 'utils/analytics/datadog';
import pendo from 'utils/analytics/pendo';
import { initializeGoogleAnalytics } from 'utils/analyticsUtils';
import { FullScreenSpinner } from './components/FullScreenSpinner';
import { fetchCurrentUser } from './store/userSlice';
import { useAppDispatch, useAppSelector } from './types/hooks';

const PRESENTATION_PATHS = ['explore/*', 'presentation/*'];

function useFullscreenPresentationMode() {
    const previousPresentationModeState = useRef<boolean | null>(null);
    const { message } = AntApp.useApp();
    const dispatch = useAppDispatch();
    const presentationModeEnabled = useAppSelector(selectPresentationModeEnabled);
    const [searchParams, setSearchParams] = useSearchParams();
    const initialSearchParams = useInitialValue(() => Object.fromEntries(searchParams.entries()));

    const exportId = searchParams.get('exportId');

    useEffect(() => {
        dispatch(initializeFromSearchParams({ searchParams: initialSearchParams }));
    }, [dispatch, initialSearchParams]);

    useEffect(() => {
        if (!presentationModeEnabled || exportId) {
            return;
        }

        const abortController = new AbortController();

        document.addEventListener(
            'fullscreenchange',
            () => {
                if (!document.fullscreenElement) {
                    dispatch(exitPresentationMode());
                }
            },
            {
                signal: abortController.signal,
            }
        );
        document.addEventListener(
            'keydown',
            (e) => {
                if (e.key === 'ESCAPE') {
                    dispatch(exitPresentationMode());
                }
            },
            {
                signal: abortController.signal,
            }
        );

        return () => {
            abortController.abort();
        };
    }, [presentationModeEnabled, dispatch, exportId]);

    useEffect(() => {
        async function handleScreen() {
            const previousState = previousPresentationModeState.current;
            previousPresentationModeState.current = presentationModeEnabled;
            try {
                if (presentationModeEnabled) {
                    if (previousState === false) {
                        message.success('Presentation mode enabled');
                    }
                    if (!searchParams.has('presentationMode')) {
                        setSearchParams((params) => {
                            params.set('presentationMode', 'true');
                            return params;
                        });
                    }
                    if (!document.fullscreenElement && !exportId) {
                        await document.documentElement.requestFullscreen();
                    }
                } else {
                    if (searchParams.has('presentationMode')) {
                        setSearchParams((params) => {
                            params.delete('presentationMode');
                            return params;
                        });
                    }
                    if (document.fullscreenElement) {
                        await document.exitFullscreen();
                    }
                    if (previousState === true) {
                        message.success('Presentation mode disabled');
                    }
                }
            } catch (error) {
                console.error('Failed to handle screen:', error);
            }
        }

        handleScreen();
    }, [presentationModeEnabled, message, setSearchParams, searchParams, exportId]);
}

export default function App(): JSX.Element {
    const dispatch = useAppDispatch();
    const { setDatadogRumUser } = useAuth();
    const [loading, setLoading] = useState(true);

    const { isDarkTheme } = useThemeChanger();

    useFullscreenPresentationMode();

    useEffect(() => {
        setDatadogRumUser();
        async function load() {
            const output = await Promise.all([
                dispatch(fetchAppConfig()).then((a) => a.payload),
                dispatch(fetchCurrentUser()).then((a) => a.payload),
                dispatch(fetchUserSettings()).then((a) => a.payload),
            ]);

            const [configs, user]: [Configuration, User, UserSettings] = output;

            initializeGoogleAnalytics(configs, user);
            pendo.start(user);
            datadog.init();
            setLoading(false);
        }

        load();
    }, [dispatch, setDatadogRumUser]);

    if (loading) return <FullScreenSpinner />;

    return (
        <>
            <GoogleFont />
            <Layout style={{ height: '100%' }} className={isDarkTheme ? 'dark-theme' : ''}>
                <Routes>
                    {PRESENTATION_PATHS.map((path) => (
                        <Route key={path} path={path} />
                    ))}
                    <Route path='*' element={<PageHeader />} />
                </Routes>
                <Outlet />
            </Layout>
        </>
    );
}
