import { useEffect } from 'react';
import { SmileOutlined } from '@ant-design/icons';
import { Result } from '@jll/react-ui-components';

const AuthPage = () => {
    useEffect(() => {
        const originalTab = window.opener;
        if (originalTab) {
            originalTab.postMessage('session-refresh', window.location.origin);
            window.close();
        }
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Result status='info' icon={<SmileOutlined />}>
                Your session has been refreshed. You can close this tab and go back to the original
                tab.
            </Result>
        </div>
    );
};

export default AuthPage;
